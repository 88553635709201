<template>
    <div v-if="naviIsVisible" class="container-w-full fixed top-0 z-10">
        <Loading :loading="isLoading" />
        <div class="pt-5 px-[5px] md:px-[15px] backdrop-blur-sm">
            <div
                class="py-2 container rounded-md shadow-sm flex justify-between items-center bg-opacity-50 backdrop-blur-md bg-fg-light dark:bg-fg-dark dark:bg-opacity-50 dark:backdrop-blur-md">
                <button @click="mobileNavigationHideShowTrigger()" class="flex justify-center items-center">
                    <font-awesome-icon class="w-5 h-5 block lg:hidden" :icon="['fa', 'bars']" />
                </button>
                <div class="flex items-center">
                    <button @click="themeSelect()" class="mr-5 flex">
                        <img class="w-6 h-6" v-if="selectedTheme === 'light'" src="../../../assets/icons/sun.png" alt="">
                        <font-awesome-icon v-else class="w-5 h-5 text-yellow-400" :icon="['fa', 'moon']" />
                    </button>
                    <button @click.stop="handleClickOutside" @click="userDropdownOptionSelect()"
                        class="w-10 h-10 flex justify-center items-center rounded-full bg-[#7284FE] relative">
                        <font-awesome-icon class="w-5 h-5 text-[#070031]" :icon="['fa', 'user-tie']" />
                        <div class="w-2 h-2 bg-green-500 rounded-full absolute bottom-[2px] right-[2px]"></div>
                        <transition name="dropdown">
                            <div ref="dropdown" v-if="isUserDropdownVisible"
                                class="w-[120px] h-[90px] rounded-md flex flex-col justify-center items-center overflow-hidden absolute top-14 right-0 bg-fg-light dark:bg-fg-dark">
                                <router-link to="/settings" class="my-3 flex items-center">
                                    <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'gear']" />
                                    <p class="text-sm">Settings</p>
                                </router-link>
                                <button @click="userDropdownOptionSelect('logout')" class="my-3 flex items-center">
                                    <font-awesome-icon class="w-4 h-4 mr-3 text-red-500"
                                        :icon="['fa', 'right-from-bracket']" />
                                    <p class="text-sm">Log Out</p>
                                </button>
                            </div>
                        </transition>
                    </button>
                </div>
            </div>
        </div>
        <transition name="mobnav">
            <div v-if="isNavigationMobileVisible" @click="mobileNavigationHideShowTrigger()" class="w-full h-screen block lg:hidden fixed top-0 left-0">
                <div @click.stop class="max-w-[300px] h-screen pt-5 bg-fg-light dark:bg-fg-dark">
                    <div class="mr-3 flex justify-end">
                        <button @click="mobileNavigationHideShowTrigger()">
                            <font-awesome-icon class="w-6 h-6" :icon="['fa', 'close']" />
                        </button>
                    </div>
                    <div @click="mobileNavigationHideShowTrigger()" class="flex justify-center">
                        <navigationLinks />
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <button v-if="isScrollBtnVisible" @click="scrollToTop()"
                class="w-9 h-9 mr-[15px] md:mr-[25px] lg:mr-[65px] rounded-full flex justify-center items-center bg-primary fixed right-0 bottom-10">
                <font-awesome-icon class="w-4 h-4 text-white" :icon="['fa', 'arrow-up']" />
            </button>
        </transition>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, onUnmounted, computed } from 'vue';
import store from '@/store/index';
import navigationLinks from './navigation-links.vue';
import { useRouter } from 'vue-router';
import Loading from '@/components/common/loading-overlay-com.vue';

const router = useRouter();

const isLoading = ref(false);
let isUserDropdownVisible = ref(false);
let selectedTheme = ref('');
const dropdown = ref(null);
let isScrollBtnVisible = ref(false);
let isNavigationMobileVisible = ref(false);

onMounted(() => {
    setTheme();
    document.addEventListener('click', handleClickOutside);
    window.addEventListener("scroll", handleScrollBtn);
});

// Cleanup the handleClickOutside event listener when component unmounts
onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});

const naviIsVisible = computed(() => {
    return store.getters.isLoggedIn;
});

//set theme
const setTheme = () => {
    let themeData = localStorage.getItem('themeData');
    if (themeData === null || themeData === undefined || themeData === '') {
        localStorage.setItem('themeData', 'light');
        selectedTheme.value = 'light';
    } else {
        selectedTheme.value = themeData;
        if (selectedTheme.value === 'dark') {
            document.documentElement.classList.toggle('dark');
        }
    };
};

//handle scroll to top button
const handleScrollBtn = () => {
    if (window.scrollY < 300) {
        isScrollBtnVisible.value = false;
    } else {
        isScrollBtnVisible.value = true;
    }
}

//scroll to top
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

//handle click outside of dropdown box
const handleClickOutside = (event) => {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
        isUserDropdownVisible.value = false;
    }
};

//select theme
const themeSelect = () => {
    document.documentElement.classList.toggle('dark');
    if (localStorage.getItem('themeData') === 'light') {
        selectedTheme.value = 'dark';
        localStorage.setItem('themeData', 'dark');
    } else {
        selectedTheme.value = 'light';
        localStorage.setItem('themeData', 'light');
    }
};

const mobileNavigationHideShowTrigger = () => {
    isNavigationMobileVisible.value = !isNavigationMobileVisible.value;
};

//user dropdown's option selection trigger
const userDropdownOptionSelect = (option) => {
    isUserDropdownVisible.value = !isUserDropdownVisible.value;
    if (option === 'logout') {
        store.dispatch('logout');
        router.push('/login');
    }
}

</script>

<style scoped>
.dropdown-enter-from,
.dropdown-leave-to {
    width: 0;
    height: 0;
    opacity: .5;
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all .3s ease;
}

.mobnav-enter-from,
.mobnav-leave-to {
    left: -100%;
}

.mobnav-enter-active,
.mobnav-leave-active {
    transition: all .3s ease;
}
</style>