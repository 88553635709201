<template>
    <div>
        <Loading :loading="isLoading" />
        <ItemEditPopup :isEditPopupVisible="isEditPopupVisible" :itemId="props.id" :title="props.title"
            :categories="props.categories" :categoryId="props.categoryId" :subCategories="subCategories"
            :subCategoryId="props.subCategoryId" :revisionDate="props.revisionDate" :price="props.price"
            :discount="props.discount" :PAD="(parseFloat(props.price) - parseFloat(props.discount)).toFixed(2)"
            :currentDocument="currentDocument" @popupClose="closeEditPopup" @changeSubCategories="getSubCategories" />
        <div
            class="w-full min-h-[320px] p-5 rounded-md flex flex-col justify-between relative group shadow-md border transition duration-500 ease-in-out bg-fg-light dark:bg-fg-dark hover:border-primary">
            <img class="max-w-14 mb-10" src="../../assets/logo/logo.png" alt="">
            <p class="mb-10 text-[3vw] md:text-[2.5vw] lg:text-[1vw] font-bold absolute top-14 line-clamp-4">{{ title }}</p>
            <div>
                <div class="mb-5 mt-20 blur-sm text-[1vw]">
                    <p class="text-[5px]">Lorem ipsum dolor, sit amet</p>
                    <p class="text-[5px]">Lorem ipsum dolor, sit amet</p>
                    <p class="text-[5px]">Lorem ipsum dolor, sit amet</p>
                    <p class="text-[5px]">Lorem ipsum dolor, sit amet</p>
                </div>
                <p class="text-[10px]">{{ revisionDate }}</p>
            </div>
        </div>
        <div class="my-5 flex">
            <button @click="openPopup()"
                class="w-full h-10 rounded-md border-2 border-primary text-primary transition duration-500 ease-in-out hover:text-white hover:bg-primary">Edit</button>
            <button @click="changeItemVisibilityTrigger()"
                class="w-10 h-10 ml-2 rounded-md border-2 border-primary flex-shrink-0 flex justify-center items-center transition duration-500 ease-in-out group hover:bg-primary">
                <font-awesome-icon v-if="isVisible === true"
                    class="w-5 h-5 text-primary transition duration-500 ease-in-out group-hover:text-white"
                    :icon="['fa', 'eye']" />
                <font-awesome-icon v-if="isVisible === false"
                    class="w-5 h-5 text-primary transition duration-500 ease-in-out group-hover:text-white"
                    :icon="['fa', 'eye-slash']" />
            </button>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps } from 'vue';
import { changeItemVisibility } from '@/services/api';
import Loading from '@/components/common/loading-overlay-com.vue';
import { swal } from '@/utils/helper';
import ItemEditPopup from '@/components/product-manager/edit-item.vue';
import { fetchSubCategories } from '@/services/api';

const props = defineProps({
    id: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },

    categories: {
        type: Object,
        default: null
    },

    categoryId: {
        type: String,
        default: ''
    },

    subCategoryId: {
        type: String,
        default: ''
    },
    price: {
        type: String,
        default: ''
    },

    discount: {
        type: String,
        default: ''
    },

    currentDocument: {
        type: String,
        default: ''
    },

    revisionDate: {
        type: String,
        default: '',
    },

    isVisible: {
        type: Boolean,
        default: true,
    }
});

//card data
let isLoading = ref(false);
// let title = ref(props.title);
// let categoryId = ref(props.categoryId);
const subCategories = ref([]);
// let subCategoryId = ref(props.subCategoryId);
// let price = ref(props.price);
// let discount = ref(props.discount);
// let revisionDate = ref(props.revisionDate);
let isVisible = ref(props.isVisible);

let isEditPopupVisible = ref(false); //edit popup visibility

//edit popup open
const openPopup = () => {
    isEditPopupVisible.value = true;
    getSubCategories();
};

const getSubCategories = async (val) => {
    isLoading.value = true;
    let selectedCategoryId = val === undefined ? props.categoryId : val;
    try {
        const response = await fetchSubCategories(selectedCategoryId);
        subCategories.value = response.data;
    } catch (error) {
        swal(error.response.data.title, error.response.data.message, 'error', false, () => { }, () => { }, () => { });
    }
    isLoading.value = false;
};

//edit popup close from emit
const closeEditPopup = (val) => {
    isEditPopupVisible.value = val;
};

const changeItemVisibilityTrigger = async () => {
    isLoading.value = true;
    try {
        const response = await changeItemVisibility(props.id);
        swal(response.data.title, response.data.message, 'success', false, () => { }, () => { }, () => { });
        isVisible.value = !isVisible.value;
    } catch (error) {
        swal(error.response.data.title, error.response.data.message, 'error', false, () => { }, () => { }, () => { });
    }
    isLoading.value = false;
}

</script>