<template>
    <div class="container-w-full min-h-screen pt-24">
        <Loading :loading="isLoading" />
        <div class="container">
            <div class="px-[5px] md:px-[15px] flex justify-end">
                <button @click="getUserReviews()" class="w-8 h-8 mr-3 rounded-md flex justify-center items-center bg-fg-light dark:bg-fg-dark">
                    <font-awesome-icon class="w-4 h-4 cursor-pointer" :icon="['fa', 'refresh']" />
                </button>
                <select v-model="reviewType" @change="getUserReviews()"
                    class="w-40 h-8 px-2 rounded-md outline-none text-sm bg-fg-light dark:bg-fg-dark" name=""
                    id="selectedType">
                    <option selected value="all">Type - All</option>
                    <option value="1">Type - Visible</option>
                    <option value="0">Type - Hidden</option>
                </select>
            </div>
            <div class="mt-5 pb-10 px-[5px] md:px-[15px]">
                <p v-if="isLoading === false && reviews.length === 0" class="mt-[300px] text-center">No review(s) to display</p>
                <div v-for="(item, index) in reviews" :key="index" class="p-5 mb-3 rounded-md flex flex-col sm:flex-row bg-fg-light dark:bg-fg-dark">
                    <div class="w-full">
                        <div class="mb-5 flex items-center">
                            <div class="w-14 h-14 mr-3 rounded-full border-2 border-primary flex justify-center items-center overflow-hidden relative">
                                <font-awesome-icon v-if="item.picture === null" class="w-10 h-10 text-gray-300 absolute bottom-0" :icon="['fa', 'user']" />
                                <img v-else :src="item.picture" alt="">
                            </div>
                            <div>
                                <p class="text-sm font-bold">{{ item.first_name + ' ' + item.last_name }}</p>
                                <div class="mt-1 flex">
                                    <font-awesome-icon v-for="index in item.rating" :key="index" class="w-4 h-4 mr-1 text-yellow-400" :icon="['fa', 'star']" />
                                </div>
                            </div>
                        </div>
                        <p>{{ item.message }}</p>
                    </div>
                    <div class="w-24 mt-5 sm:mt-0 flex-shrink-0">
                        <button @click="changeReviewVisibilityType(index)" class="w-full py-2 rounded-md font-bold text-white bg-primary">{{ item.is_active == 1 ? 'Hide' : 'Show' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { fetchAllUserReviews, changeReviewVisibility } from '@/services/api';
import Loading from '@/components/common/loading-overlay-com.vue';
import { swal } from '@/utils/helper';

const isLoading = ref(true);
const reviewType = ref('all');
const reviews = ref([]);

onMounted(() => {
    getUserReviews();
});

const getUserReviews = async () => {
    isLoading.value = true;
    try {
        const response = await fetchAllUserReviews(reviewType.value);
        reviews.value = response.data;
    } catch (error) {
        swal(error.response.data.title, error.response.data.message, 'error', false, () => {}, () => {}, () => {});
    }
    isLoading.value = false;
};

const changeReviewVisibilityType = async (index) => {
    isLoading.value = true;
    try {
        const response = await changeReviewVisibility(reviews.value[index].id);
        reviews.value[index].is_active = reviews.value[index].is_active == 1 ? 0 : 1;
        swal(response.data.title, response.data.message, 'success', false, () => {}, () => {}, () => {});
    } catch (error) {
        swal(error.response.data.title, error.response.data.message, 'error', false, () => {}, () => {}, () => {});
    }
    isLoading.value = false;
};
</script>