<template>
    <div class="w-full mb-7">
        <p class="text-red">{{ props.label }}</p>
        <input v-model="inputValue" @input="emitNewValue()" v-bind:disabled="props.isDissabled" class="w-full h-10 px-3 rounded-md border bg-fg-light dark:bg-fg-dark"
            :class="props.error !== '' ? 'border-red-600' : 'border-stone-200 dark:border-stone-600'" :type="props.type" name="" :id="id"
            :placeholder="props.placeholder">
        <p v-if="props.error !== ''" class="text-sm text-red-600">{{ props.error }}</p>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    
    type: {
        type: String,
        default: 'text'
    },
    
    label: {
        type: String,
        default: '',
    },

    value: {
        type: String,
        default: '',
    },

    placeholder: {
        type: String,
        default: '',
    },

    error: {
        type: String,
        default: '',
    },

    isDissabled: {
        type: Boolean,
        default: false,
    }
});

let inputValue = ref(props.value);

const emits = defineEmits(['inputFieldValueChanged']);

const emitNewValue = () => {
    emits('inputFieldValueChanged', inputValue.value);
};

</script>