import { createApp } from 'vue';
import App from '@/App.vue';
import store from './store';
import Router from '@/router/index';
import '@/assets/styles/tailwind.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

createApp(App)
.use(store)
.use(Router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
