import Swal from "sweetalert2";
import { CONSTANTS } from "./constants";

//sweet alert
function swal(title, text, icon, cancelBtn, isAllFunc, isConfirmedFunc, isDeniedFunc) {
  let themeData = localStorage.getItem('themeData');

  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: cancelBtn,
    cancelButtonColor: 'red',
    confirmButtonColor: CONSTANTS.COLOR_PRIMARY,
    color: themeData === 'dark' ? 'white' : 'black',
    background: themeData === 'dark' ? CONSTANTS.COLOR_FG_DARK : '',
  }).then((result) => {
    if (isAllFunc.toString() !== '() => {}') {
      isAllFunc();
    } else {
      if (result.isConfirmed) {
        isConfirmedFunc();
      } else if (result.dismiss === 'cancel') {
        isDeniedFunc();
      } else {
        isAllFunc();
      }
    }
  })
}

//intersection observer
export function createObserver(target, handleIntersectionCallback) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        handleIntersectionCallback();
      }
    });
  }, options);

  observer.observe(target);
}

export { swal };