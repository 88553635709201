import { createStore } from 'vuex'

const store = createStore({
  state: {
    token: localStorage.getItem('token') || null
  },

  mutations: {
    setToken(state, token) {
      state.token = token
    },

    removeToken(state) {
      state.token = null
    }
  },

  actions: {
    login({ commit }, token) {
      localStorage.setItem('token', token)
      commit('setToken', token)
    },
    
    logout({ commit }) {
      localStorage.removeItem('token')
      commit('removeToken')
    }
  },

  getters: {
    isLoggedIn: state => state.token !== null,
    token: state => state.token
  }
})

export default store
