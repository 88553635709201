<template>
    <div class="container-w-full min-h-screen pt-24">
        <Loading :loading="isLoading" />
        <div class="px-[5px] md:px-[15px]">
            <div class="flex items-center">
                <font-awesome-icon class="w-4 h-4 mr-3" :icon="['fa', 'lock']" />
                <span class="">Security</span>
            </div>
            <div class="mt-5 container py-10 rounded-md bg-fg-light dark:bg-fg-dark">
                <p class="mb-10 text-lg">Change Password</p>
                <div class="mb-5 grid gap-4 grid-cols-1 sm:grid-cols-2">
                    <div>
                        <p class="text-sm" for="">Old Password</p>
                        <div class="flex border rounded-md" :class="errors.oldPassword !== '' ? 'border-red-600 dark:border-red-600' : 'dark:border-gray-600'">
                            <input v-model="oldPassword" class="w-full h-11 pl-3 rounded-md bg-fg-light dark:bg-fg-dark" :type="inputType.oldPassword" name="" id="oldPassword">
                            <div class="w-6 h-11 flex items-center flex-shrink-0 dark:border-gray-500">
                                <font-awesome-icon @click="changeinputTypeIcon('oldPassword')" class="w-4 h-4 mr-3 text-white cursor-pointer" :icon="inputType.oldPassword === 'password' ? ['fa', 'eye'] : ['fa', 'eye-slash']" />
                            </div>
                        </div>
                        <p v-if="errors.oldPassword !== ''" class="text-sm text-red-600">{{ errors.oldPassword }}</p>
                    </div>
                </div>
                <div class="mb-7 grid gap-4 grid-cols-1 sm:grid-cols-2">
                    <div>
                        <p class="text-sm">New Password</p>
                        <div class="flex border rounded-md"  :class="errors.newPassword !== '' ? 'border-red-600 dark:border-red-600' : 'dark:border-gray-600'">
                            <input v-model="newPassword" class="w-full h-11 pl-3 rounded-md bg-fg-light dark:bg-fg-dark dark:border-gray-500" :type="inputType.newPassword" name="" id="newPassword">
                            <div class="w-6 h-11 flex items-center flex-shrink-0 dark:border-gray-500">
                                <font-awesome-icon @click="changeinputTypeIcon('newPassword')" class="w-4 h-4 mr-3 text-white cursor-pointer" :icon="inputType.newPassword === 'password' ? ['fa', 'eye'] : ['fa', 'eye-slash']" />
                            </div>
                        </div>
                        <p v-if="errors.newPassword !== ''" class="text-sm text-red-600">{{ errors.newPassword }}</p>
                    </div>
                    <div>
                        <p class="text-sm" for="">Confirm Password</p>
                        <div class="flex border rounded-md"  :class="errors.confirmPassword !== '' ? 'border-red-600 dark:border-red-600' : 'dark:border-gray-600'">
                            <input v-model="confirmPassword" class="w-full h-11 pl-3 rounded-md bg-fg-light dark:bg-fg-dark dark:border-gray-500" :type="inputType.confirmPassword" name="" id="confirmPassword">
                            <div class="w-6 h-11 flex items-center flex-shrink-0 dark:border-gray-500">
                                <font-awesome-icon @click="changeinputTypeIcon('confirmPassword')" class="w-4 h-4 mr-3 text-white cursor-pointer" :icon="inputType.confirmPassword === 'password' ? ['fa', 'eye'] : ['fa', 'eye-slash']" />
                            </div>
                        </div>
                        <p v-if="errors.confirmPassword !== ''" class="text-sm text-red-600">{{ errors.confirmPassword }}</p>
                    </div>
                </div>
                <button @click="saveChanges()" class="h-11 px-9 rounded-md text-white bg-primary">Save Changes</button>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { changeAdminPassword } from '@/services/api';
import { swal } from '@/utils/helper';
import Loading from '@/components/common/loading-overlay-com.vue';

let isLoading = ref(false);
let oldPassword = ref('');
let newPassword = ref('');
let confirmPassword = ref('');
let errors = ref({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
});
let inputType = ref({
    oldPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password',
});

//change show hide icon related to input type
const changeinputTypeIcon = (type) => {
    if(inputType.value[type] === 'password') {
        inputType.value[type] = 'text';
    } else {
        inputType.value[type] = 'password';
    }
};

//input fields default validation
const validation = () => {
    if(oldPassword.value === '') {
        errors.value.oldPassword = 'Old password is required!';
    } else {
        errors.value.oldPassword = '';
    }

    if(newPassword.value === '') {
        errors.value.newPassword = 'New password is required!';
    } else if(newPassword.value.length <= 7) {
        errors.value.newPassword = 'Password should contained 8 or more characters!';
    } else {
        errors.value.newPassword = '';
    }

    if(confirmPassword.value === '') {
        errors.value.confirmPassword = 'Password confitmation is required!';
    } else if(newPassword.value !== confirmPassword.value) {
        errors.value.confirmPassword = 'Password confitmation did not matched with the new password!';
    } else {
        errors.value.confirmPassword = '';
    }
};

const saveChanges = async() => {
    validation();

    if(errors.value.oldPassword === '' && errors.value.newPassword === '' && errors.value.confirmPassword === '') {
        const postData = {
            oldPassword: oldPassword.value,
            newPassword: newPassword.value,
            passwordConfirm: confirmPassword.value,
        };

        isLoading.value = true;
        try {
            const response = await changeAdminPassword(postData);
            swal(response.data.title, response.data.message, 'success', false, () => { }, () => { }, () => { });
        } catch (error) {
            swal(error.response.data.title, error.response.data.message, 'error', false, () => { }, () => { }, () => { });
        }
        isLoading.value = false;
    } 
};

</script>