<template>
    <div class="container-w-full min-h-screen pt-24">
        <Loading :loading="isLoading" />
        <div class="container">
            <div class="px-[5px] lg:px-[15px] pb-20 flex flex-col items-center">
                <div class="w-full lg:grid lg:gap-10 lg:grid-cols-2">
                    <!-- add a new category -->
                    <div class="flex flex-col items-center lg:items-start">
                        <p class="max-w-[550px] w-full mb-10 text-primary text-2xl font-bold text-left">Add a New Category
                        </p>
                        <div class="max-w-[550px] w-full">
                            <InputTextLabel :id="'newCategoryName'" :type="'text'" :label="'New Category Name:'"
                                :placeholder="'cat - 1'" :value="newCategory" :error="errors.newCategory"
                                :isDissabled="false" @inputFieldValueChanged="newCategoryInputFieldValueChange" />
                            <BtnText :buttonText="'Add Category'" @clickEvent="addNewCategory()" />
                        </div>
                    </div>
                    <div class="w-full h-[1px] my-10 block lg:hidden bg-fg-light dark:bg-fg-dark"></div>
                    <!-- add a new sub category -->
                    <div class="mt-10 lg:mt-0 flex flex-col items-center lg:items-end">
                        <div class="max-w-[550px] w-full">
                            <p class="w-full mb-10 text-primary text-2xl font-bold text-left">Add a New Sub
                                Category</p>
                            <InputSelectLabel :id="'selectedCategory'" :label="'Select a Category:'"
                                :value="selectedSCIForSubCategory" :options="categories"
                                :extraOption="{ name: 'select a category', value: '', isDissabled: true, isVisible: true }"
                                :error="errors.selectedSCIForSubCategory"
                                @inputFieldValueChanged="selectedSCIForSubCategoryInputFieldValueChange" />
                            <InputTextLabel :id="'newSubCategoryName'" :type="'text'" :label="'New Sub Category Name:'"
                                :placeholder="'sub cat - 1'" :value="newSubCategory" :error="errors.newSubCategory"
                                :isDissabled="false" @inputFieldValueChanged="newSubCategoryInputFieldValueChange" />
                            <BtnText :buttonText="'Add Sub Category'" @clickEvent="addNewSubCategory()" />
                        </div>
                    </div>
                </div>
                <div class="w-full h-[1px] my-10 bg-fg-light dark:bg-fg-dark"></div>
                <div class="w-full flex flex-col lg:flex-row lg:items-start justify-between">
                    <!-- add a new item -->
                    <div class="w-full flex justify-center lg:justify-start">
                        <div class="max-w-[550px] w-full">
                            <InputTextLabel :id="'title'" :type="'text'" :label="'Title:'" :placeholder="'Marketing Plan'"
                                :value="title" :error="errors.title" :isDissabled="false"
                                @inputFieldValueChanged="titleInputFieldValueChange" />
                            <InputSelectLabel :id="'selectedCategoryId'" :label="'Select a Category:'"
                                :value="selectedCategoryId" :options="categories"
                                :extraOption="{ name: 'select a category', value: '', isDissabled: true, isVisible: true }"
                                :error="errors.selectedCategoryId"
                                @inputFieldValueChanged="selectedCategoryIdInputFieldValueChange" />
                            <InputSelectLabel :id="'selectedSubCategoryId'" :label="'Select a Sub Category:'"
                                :value="selectedSubCategoryId" :options="subCategories"
                                :extraOption="{ name: 'select a sub category', value: '', isDissabled: true, isVisible: true }"
                                :error="errors.selectedSubCategoryId" :isDissabled="!selectedCategoryId"
                                @inputFieldValueChanged="selectedSubCategoryIdInputFieldValueChange" />
                            <InputTextLabel :id="'revisionDate'" :type="'date'" :label="'Revision Date:'" :placeholder="''"
                                :value="revisionDate" :error="errors.revisionDate" :isDissabled="false"
                                @inputFieldValueChanged="revisionDateInputFieldValueChange" />
                            <div class="grid grid-cols-2 gap-4">
                                <InputTextLabel :id="'price'" :type="'text'" :label="`Price (${CONSTANTS.CURRECNCY_TYPE}):`"
                                    :placeholder="'1.50'" :value="price" :error="errors.price" :isDissabled="false"
                                    @inputFieldValueChanged="priceInputFieldValueChange" />
                                <InputTextLabel :id="'discount'" :type="'text'"
                                    :label="`Discount (${CONSTANTS.CURRECNCY_TYPE}):`" :placeholder="'0.50'"
                                    :value="discount" :error="errors.discount" :isDissabled="false"
                                    @inputFieldValueChanged="discountInputFieldValueChange" />
                            </div>
                            <div class="max-w-[550px] w-full mb-10 grid grid-cols-2 gap-4">
                                <div>
                                    <p>PAD {{ `(${CONSTANTS.CURRECNCY_TYPE})` }}</p>
                                    <div
                                        class="w-full h-10 pl-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark">
                                        <p>{{ PAD }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="max-w-[550px] mb-10">
                                <p>Upload Document (.doc .docx .pdf .ppt .pptx)</p>
                                <input class="max-w-[550px] w-full border rounded-md"
                                    :class="errors.documentFile !== '' ? 'border-red-600' : ''" type="file" ref="document"
                                    @change="documentUpload()" name="" id="document" accept=".doc, .docx, .pdf, .ppt, .pptx">
                                <p v-if="errors.documentFile" class="text-sm text-red-600">{{ errors.documentFile }}</p>
                                <p v-if="documentFile && documentFile.name.split('.').pop() !== 'docx'" class="mt-1 text-sm text-orange-400">Warn: Preview is only available for .docx files.</p>
                                <p v-if="documentFile && documentFile.name.split('.').pop() === 'doc'" class="mt-1 text-sm text-green-600">Tip: You can prevent this issue by converting the .doc file to .docx before uploading.</p>
                            </div>
                            <BtnText class="hidden lg:block" :buttonText="'Add Item'" @clickEvent="addDocument()" />
                        </div>
                    </div>
                    <!-- item preview -->
                    <div class="lg:mt-[95px] flex justify-center lg:justify-end">
                        <div
                            class="max-w-[550px] w-full lg:w-[400px] min-h-[500px] lg:min-h-[700px] xl:w-[500px] p-[40px] lg:ml-5 rounded-lg flex flex-col justify-between flex-shrink-0 border overflow-hidden border-white bg-fg-light dark:bg-fg-dark">
                            <div>
                                <img class="mb-10 lg:mb-20 w-48" src="../assets/logo/logo.png" alt="">
                                <p class="text-[7vw] lg:text-[2vw] font-bold">{{ title }}</p>
                            </div>
                            <div>
                                <div class="mb-10 mt-20 blur-sm text-[1vw]">
                                    <p>Lorem ipsum dolor, sit amet</p>
                                    <p>Lorem ipsum dolor, sit amet</p>
                                    <p>Lorem ipsum dolor, sit amet</p>
                                    <p>Lorem ipsum dolor, sit amet</p>
                                </div>
                                <p v-if="revisionDate" class="pb-[10px] lg:pb-[40px] text-sm lg:text-[1vw]">Revised: {{
                                    revisionDate }}</p>
                            </div>
                        </div>
                    </div>
                    <BtnText class="max-w-[550px] w-full mt-10 lg:hidden" :buttonText="'Add Item'" @clickEvent="addDocument()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';
import { fetchCategories, fetchSubCategories, addCategory, addSubCategory, addItem } from '@/services/api';
import InputTextLabel from '@/components/common/label-input-types/label-text.vue';
import InputSelectLabel from '@/components/common/label-input-types/label-select.vue';
import BtnText from '@/components/common/buttons/btn-text.vue';
import Loading from '@/components/common/loading-overlay-com.vue';
import { swal } from '@/utils/helper';
import { CONSTANTS } from '@/utils/constants';

//common
const isLoading = ref(false);

//add a new category
let newCategory = ref('');

//add a sub category
let selectedSCIForSubCategory = ref('');
let newSubCategory = ref('');

//add a item
let title = ref('');
let revisionDate = ref('');
let categories = ref();
const selectedCategoryId = ref('');
let subCategories = ref();
const selectedSubCategoryId = ref('');
let price = ref('');
let discount = ref('');
let PAD = ref('');
let document = ref(null); //ref to document input field
let documentFile = ref(null);

//inputs errors
let errors = ref({
    newCategory: '',
    selectedSCIForSubCategory: '',
    newSubCategory: '',
    title: '',
    revisionDate: '',
    selectedCategoryId: '',
    selectedSubCategoryId: '',
    price: '',
    discount: '',
    documentFile: '',
});

onMounted(() => {
    //call get categories api
    getCategories();
});

watch(selectedCategoryId, (val) => {
    //call get sub categories api
    getSubCategories(val);
})

// declare get categories api
const getCategories = async () => {
    const response = await fetchCategories();
    categories.value = response.data;
};

// declare get sub categories api
const getSubCategories = async (categoryId) => {
    const response = await fetchSubCategories(categoryId);
    subCategories.value = response.data;
};

/////get InputTextLabel / InputSelectLabel changed values/////

const newCategoryInputFieldValueChange = (val) => {
    newCategory.value = val;
    errors.value.newCategory = '';
}

const selectedSCIForSubCategoryInputFieldValueChange = (val) => {
    selectedSCIForSubCategory.value = val;
    errors.value.selectedSCIForSubCategory = '';
}

const newSubCategoryInputFieldValueChange = (val) => {
    newSubCategory.value = val;
    errors.value.newSubCategory = '';
}

const titleInputFieldValueChange = (val) => {
    title.value = val;
    errors.value.title = '';
}

const selectedCategoryIdInputFieldValueChange = (val) => {
    selectedCategoryId.value = val;
    errors.value.selectedCategoryId = '';
}

const selectedSubCategoryIdInputFieldValueChange = (val) => {
    selectedSubCategoryId.value = val;
    errors.value.selectedSubCategoryId = '';
}

const revisionDateInputFieldValueChange = (val) => {
    revisionDate.value = val;
    errors.value.revisionDate = '';
}

const priceInputFieldValueChange = (val) => {
    price.value = val;
    errors.value.price = '';
    calculatePAD();
}

const discountInputFieldValueChange = (val) => {
    discount.value = val;
    errors.value.discount = '';
    calculatePAD();
}

///////

//add a new document form validation
const formValidation = () => {

    //title field
    if (title.value === '') {
        errors.value.title = 'Title is required!';
    } else {
        errors.value.title = '';
    }

    //category field
    if (selectedCategoryId.value === '') {
        errors.value.selectedCategoryId = 'Category is required!';
    } else {
        errors.value.selectedCategoryId = '';
    }

    //sub category field
    if (selectedSubCategoryId.value === '') {
        errors.value.selectedSubCategoryId = 'Sub category is required!';
    } else {
        errors.value.selectedSubCategoryId = '';
    }

    //revision date field
    if (revisionDate.value === '') {
        errors.value.revisionDate = 'Revision date is required!';
    } else {
        errors.value.revisionDate = '';
    }

    //price field
    if (price.value === '') {
        errors.value.price = 'Price is required!';
    } else if (isNaN(price.value)) {
        errors.value.price = 'Invalid input!';
    } else {
        errors.value.price = '';
    }

    // discount field
    if (isNaN(discount.value)) {
        errors.value.discount = 'Invalid input!';
    } else if (price.value < discount.value) {
        errors.value.discount = 'Discount must be less than the price!';
    } else {
        errors.value.discount = '';
    }

    //revision date field
    if (documentFile.value === null) {
        errors.value.documentFile = 'Document is required!';
    } else {
        errors.value.documentFile = '';
    }
};

//calculate Price after discount
const calculatePAD = () => {
    let priceAfterDiscount = price.value - discount.value;
    if (!isNaN(priceAfterDiscount) && priceAfterDiscount >= 0) {
        PAD.value = priceAfterDiscount.toFixed(2);
    } else {
        PAD.value = '';
    }
};

//document file upload
const documentUpload = () => {
    documentFile.value = document.value.files[0];    
}

//add new category
const addNewCategory = async () => {
    isLoading.value = true;
    errors.value.newCategory = '';

    if (newCategory.value === '') {
        errors.value.newCategory = 'Category name is required!';
    } else {
        const postData = {
            name: newCategory.value,
        };

        try {
            const response = await addCategory(postData);
            swal(
                response.data.title,
                response.data.message,
                'success',
                false, () => {
                    window.location.reload();
                },
                () => { },
                () => { }
            );
        } catch (error) {
            swal(error.response.data.title, error.response.data.message, 'error', false, () => { }, () => { }, () => { });
        }
    }
    isLoading.value = false;
};

const addNewSubCategory = async () => {
    isLoading.value = true;

    if (selectedSCIForSubCategory.value === '') {
        errors.value.selectedSCIForSubCategory = 'Category is required!';
    } else {
        errors.value.selectedSCIForSubCategory = '';
    }

    if (newSubCategory.value === '') {
        errors.value.newSubCategory = 'Sub category name is required!';
    } else {
        errors.value.newSubCategory = '';
    }

    if (errors.value.selectedSCIForSubCategory === '' && errors.value.newSubCategory === '') {
        const postData = {
            categoryId: selectedSCIForSubCategory.value,
            name: newSubCategory.value,
        };

        try {
            const response = await addSubCategory(postData);
            swal(response.data.title, response.data.message, 'success', false, () => { }, () => { }, () => { });
            selectedSCIForSubCategory.value = '';
            newSubCategory.value = '';
            window.location.reload();
        } catch (error) {
            swal(error.response.data.title, error.response.data.message, 'error', false, () => { }, () => { }, () => { });
        }
    };

    isLoading.value = false;
};

//add document 
const addDocument = async () => {
    isLoading.value = true;
    formValidation();
    if (errors.value.title === '' && errors.value.selectedCategoryId === '' && errors.value.selectedSubCategoryId === '' && errors.value.revisionDate === '' && errors.value.price === '' && errors.value.discount === '' && errors.value.documentFile === '') {
        try {
            let postData = new FormData();

            postData.append('title', title.value);
            postData.append('revisionDate', revisionDate.value);
            postData.append('categoryId', selectedCategoryId.value);
            postData.append('subCategoryId', selectedSubCategoryId.value);
            postData.append('price', price.value);
            postData.append('discount', discount.value);
            postData.append('file', documentFile.value);

            const response = await addItem(postData);
            swal(
                response.data.title, 
                response.data.message, 
                'success', 
                false, 
                () => {
                    window.location.reload();
                }, 
                () => { }, 
                () => { }
            );
        } catch (error) {
            swal(error.response.data.title, error.response.data.message, 'error', false, () => { }, () => { }, () => { });
        }
    }
    isLoading.value = false;
};

</script>
