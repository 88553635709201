<template>
    <div class="max-w-[550px] w-full mb-10">
        <p class="text-red">{{ props.label }}</p>
        <select v-bind:disabled="props.isDissabled" v-model="inputValue" @change="emitNewValue()" class="w-full h-10 pl-3 rounded-md border bg-fg-light dark:bg-fg-dark"
        :class="props.error !== '' ? 'border-red-600' : 'border-stone-200 dark:border-stone-600'" name="" :id="id">
            <option v-if="props.extraOption.isVisible" selected disabled :value="props.extraOption.value">{{ props.extraOption.name }}</option>
            <option v-for="(item, index) in options" :key="index" :value="item.id">{{ item.name }}</option>
        </select>
        <p class="text-sm text-red-600">{{ props.error }}</p>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
    id: {
        type: String,
        default: ''
    },

    label: {
        type: String,
        default: '',
    },

    value: {
        type: String,
        default: 'all',
    },

    options: {
        type: Object,
        default: null
    },

    extraOption: {
        type: Object,
        default: { name: '', value: '', isDissabled: true, isVisible: false }
    },

    error: {
        type: String,
        default: '',
    },

    isDissabled: {
        type: Boolean,
        default: false,
    }
});

let inputValue = ref(props.value);

const emits = defineEmits(['inputFieldValueChanged']);

const emitNewValue = () => {
    emits('inputFieldValueChanged', inputValue.value);
};

</script>