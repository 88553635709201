<template>
    <div class="h-screen p-5 flex justify-center items-center">
        <Loading :loading="isLoading" />
        <div class="max-w-[400px] w-full">
            <img class="w-[200px] mb-10" src="../assets/logo/logo.png" alt="">
            <p class="mb-1 font-bold">Welcome to OUR TEMPLATES!</p>
            <p class="mb-10 text-sm">Please sign-in to your account and start the adventure</p>
            <InputLabelText :id="'username'" :type="'text'" :label="'Username'" :value="username" :placeholder="'example@123'" :error="errors.username" @inputFieldValueChanged="usernameValueChanged" />
            <InputLabelText :id="'password'" :type="'password'" :label="'Password'" :value="username" :placeholder="'example@123'" :error="errors.username" @inputFieldValueChanged="passwordValueChanged" />
            <ButtonText :buttonText="'Login'" @clickEvent="login()"/>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import store from '@/store/index';
import { useRouter } from 'vue-router';
import InputLabelText from '@/components/common/label-input-types/label-text.vue';
import ButtonText from '@/components/common/buttons/btn-text.vue';
import { adminLogin } from '@/services/api';
import Loading from '@/components/common/loading-overlay-com.vue';
import { swal } from '@/utils/helper';

const router = useRouter();

const isLoading = ref(false);

const username = ref('');
const password = ref('');
const errors = ref({
    username: '',
    password: '',
})

const usernameValueChanged = (val) => {
    username.value = val;
};

const passwordValueChanged = (val) => {
    password.value = val;
};

const validation = () => {
    if(username.value === '') {
        errors.value.username = 'Username is required!';
    } else {
        errors.value.username = '';
    }

    if(password.value === '') {
        errors.value.password = 'Password is required!';
    } else {
        errors.value.password = '';
    }
};

const login = async () => {
    isLoading.value = true;
    validation();

    if(errors.value.username === '' && errors.value.password === '') {
        const postData = {
            username: username.value,
            password: password.value,
        };

        try {
            const response = await adminLogin(postData);
            store.dispatch('login', response.data.token);
            router.push('/product-manager');
        } catch (error) {
            swal(error.response.data.title, error.response.data.message, 'error', false, () => { router.push('/login') }, () => { }, () => { });
        }
    }
    
    isLoading.value = false;
};

</script>