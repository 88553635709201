<template>
    <div class="container-w-full min-h-screen pt-24">
        <Loading :loading="isLoading" />
        <div class="container">
            <!-- FIlter -->
            <div>
                <!-- lg -->
                <div class="px-[5px] md:px-[15px] hidden lg:flex justify-end">
                    <select v-model="selectedType" @change="getItems(false)"
                        class="w-52 h-8 px-2 mr-5 rounded-md outline-none text-sm bg-fg-light dark:bg-fg-dark" name=""
                        id="selectedType">
                        <option selected value="all">Type - All</option>
                        <option value="1">Type - Visible</option>
                        <option value="0">Type - Hidden</option>
                    </select>
                    <select v-model="selectedCategory" @change="getItems(false)"
                        class="w-52 h-8 px-2 mr-5 rounded-md outline-none text-sm bg-fg-light dark:bg-fg-dark" name=""
                        id="selectedCategory">
                        <option selected value="all">Category - All</option>
                        <option v-for="(item, index) in categories" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                    <select v-bind:disabled="selectedCategory === 'all'" v-model="selectedSubCategory" @change="getItems(false)"
                        class="w-52 h-8 px-2 mr-5 rounded-md outline-none text-sm bg-fg-light dark:bg-fg-dark" name=""
                        id="selectedSubCategory">
                        <option selected value="all">Sub Category - All</option>
                        <option v-for="(item, index) in subCategories" :key="index" :value="item.id">{{ item.name }}
                        </option>
                    </select>
                    <div class="flex">
                        <input v-model="searchKey"
                            class="w-52 h-8 px-2 outline-none rounded-tl-md rounded-bl-md text-sm bg-fg-light dark:bg-fg-dark"
                            type="text" name="" id="searchKey" placeholder="Search">
                        <button @click="getItems(false)"
                            class="w-8 h-8 rounded-tr-md rounded-br-md flex justify-center items-center bg-primary">
                            <font-awesome-icon class="w-4 h-4 text-white" :icon="['fa', 'magnifying-glass']" />
                        </button>
                    </div>
                </div>
                <!-- sm -->
                <div class="mr-4 flex justify-end lg:hidden">
                    <!-- btn -->
                    <button @click="smFilterNavigationHideShowTrigger()" class="w-8 h-8 flex justify-center items-center rounded-md bg-fg-light dark:bg-fg-dark">
                        <font-awesome-icon class="w-4 h-4" :icon="['fa', 'sliders']" />
                    </button>
                    <!-- filter navigation -->
                    <transition name="filter">
                        <div v-if="isSmFilterNavigationVisible" @click="smFilterNavigationHideShowTrigger()"
                            class="w-full h-screen flex justify-end fixed top-0 right-0 z-10">
                            <div @click.stop class="max-w-[300px] w-full h-screen p-5 bg-fg-light dark:bg-fg-dark">
                                <button @click="smFilterNavigationHideShowTrigger()" class="mb-10">
                                    <font-awesome-icon class="w-5 h-5" :icon="['fa', 'close']" />
                                </button>
                                <div>
                                    <input v-model="searchKey"
                                        class="w-full h-8 px-2 mb-8 outline-none rounded-md border text-sm bg-fg-light dark:bg-fg-dark"
                                        type="text" name="" id="" placeholder="Search">
                                    <select v-model="selectedType"
                                        class="w-full h-8 px-2 mb-8 rounded-md outline-none border text-sm bg-fg-light dark:bg-fg-dark"
                                        name="" id="">
                                        <option selected value="all">Type - All</option>
                                        <option value="1">Type - Visible</option>
                                        <option value="0">Type - Hidden</option>
                                    </select>
                                    <select v-model="selectedType"
                                        class="w-full h-8 px-2 mb-8 rounded-md outline-none border text-sm bg-fg-light dark:bg-fg-dark"
                                        name="" id="">
                                        <option selected value="all">Category - All</option>
                                        <option v-for="(item, index) in categories" :key="index" :value="item.id">{{
                                            item.name
                                        }}</option>
                                    </select>
                                    <select v-model="selectedType"
                                        class="w-full h-8 px-2 mb-5 rounded-md outline-none border text-sm bg-fg-light dark:bg-fg-dark"
                                        name="" id="">
                                        <option selected value="all">Sub Category - All</option>
                                        <option v-for="(item, index) in subCategories" :key="index" :value="item.id">{{
                                            item.name }}
                                        </option>
                                    </select>
                                    <button @click="getItems(false)" class="w-full px-3 py-1 rounded-md text-white bg-primary">Apply</button>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="container mt-5 pb-20">
            <div class="px-[5px] md:px-[15px] mb-5 text-sm flex items-center gap-1">
                <!-- Type -->
                <p>{{ selectedType === '1' ? 'Visible' : selectedType === '0' ? 'Hidden' : 'All' }}</p>
                <!-- Category -->
                <div v-if="selectedCategory !== 'all'" class="flex items-center gap-1">
                    <font-awesome-icon class="w-3 h-3" :icon="['fa', 'chevron-right']" />
                    <p>{{ categories.find(cat => cat.id === selectedCategory).name }}</p>
                </div>
                <!-- Sub Category -->
                <div v-if="selectedSubCategory !== 'all'" class="flex items-center gap-1">
                    <font-awesome-icon class="w-3 h-3" :icon="['fa', 'chevron-right']" />
                    <p>{{ subCategories.find(cat => cat.id = selectedSubCategory).name }}</p>
                </div>
                <!-- Search -->
                <div v-if="searchKey" class="flex items-center gap-1">
                    <font-awesome-icon class="w-3 h-3" :icon="['fa', 'chevron-right']" />
                    <p>{{ searchKey }}</p>
                </div>
                <button @click="resetFilter()" v-if="selectedType !== 'all' || selectedCategory !== 'all' || selectedSubCategory !== 'all' || searchKey !== ''">
                    <font-awesome-icon class="w-[14px] h-[14px]" :icon="['fa', 'fa-xmark']" />
                </button>
            </div>
            <p v-if="isLoading === false && items.length === 0" class="mt-[300px] text-center">No document(s) to display</p>
            <div
                class="px-[5px] md:px-[15px] grid gap-4 grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xm:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5">
                <div v-for="(item, index) in items" :key="index">
                    <ProductList :id="item.id" :title="item.title" :categories="categories" :categoryId="item.category_id" :subCategoryId="item.sub_category_id"
                        :price="item.price" :discount="item.discount" :currentDocument="item.file" :revisionDate="item.revision_date" :isVisible="item.is_visible" />
                </div>
            </div>
            <div ref="refItemsEnd"></div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import ProductList from '@/components/product-manager/item-card-com.vue';
import { fetchCategories, fetchSubCategories, fetchItems } from '@/services/api';
import Loading from '@/components/common/loading-overlay-com.vue';
import { swal, createObserver } from '@/utils/helper';

let isLoading = ref(true);
const categories = ref([]);
const subCategories = ref([]);
const selectedType = ref('all');
const selectedCategory = ref('all');
const selectedSubCategory = ref('all');
let searchKey = ref('');
const items = ref([]);
let itemsPage = ref(1);
const refItemsEnd = ref(null);
const isSmFilterNavigationVisible = ref(false);

const router = useRouter();

onMounted(() => {
    getCategories();
    getItems(false);
    createObserver(refItemsEnd.value, () => getItems(true));
});

watch(selectedCategory, (val) => {
    getSubCategories(val);
});

// declare get categories api
const getCategories = async () => {
    // isLoading.value = true;
    const response = await fetchCategories();
    categories.value = response.data;
    // isLoading.value = false;
};

// declare get sub categories api
const getSubCategories = async (categoryId) => {
    if (selectedCategory.value !== 'all') {
        isLoading.value = true;
        const response = await fetchSubCategories(categoryId);
        subCategories.value = response.data;
        isLoading.value = false;
    }
};

const getItems = async (isGetPage) => {
    isSmFilterNavigationVisible.value = false;
    isLoading.value = true;

    if(selectedCategory.value === 'all') {
        selectedSubCategory.value = 'all';
    }

    try {
        if(isGetPage === false) {
            items.value = [];
            itemsPage.value = 1;
            const response = await fetchItems(itemsPage.value, selectedType.value, selectedCategory.value, selectedSubCategory.value, searchKey.value);
            items.value = response.data;
        } else {
                itemsPage.value += 1;
                const response = await fetchItems(itemsPage.value, selectedType.value, selectedCategory.value, selectedSubCategory.value, searchKey.value);
                let newItems = response.data;
                newItems.forEach(item => {
                    items.value.push(item)
                });
        }
    } catch (error) {
        swal(error.response.data.title, error.response.data.message, 'error', false, () => { localStorage.removeItem('token'); router.push('/login') }, () => { }, () => { });
    }
    isLoading.value = false;
};

const resetFilter = () => {
    selectedType.value = 'all';
    selectedCategory.value = 'all';
    selectedSubCategory.value = 'all';
    searchKey.value = '';
    getItems(false);
};

const smFilterNavigationHideShowTrigger = () => {
    isSmFilterNavigationVisible.value = !isSmFilterNavigationVisible.value;
};

</script>

<style scoped>
.filter-enter-from,
.filter-leave-to {
    right: -100%;
}

.filter-enter-active,
.filter-leave-active {
    transition: all .3s ease;
}
</style>