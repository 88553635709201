import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store/index';
import ProductManager from '@/views/product-manager-scr.vue';
import ProductAdd from '@/views/product-add-scr.vue';
import ManageReviews from '@/views/manage-reviews-scr.vue';
import Settings from '@/views/settings-scr.vue';
import Login from '@/views/login-scr.vue';

const routes = [
    {
        name: 'Product Manager',
        path: '/product-manager',
        component: ProductManager,
        meta: {
            title: 'Product Manager',
            requiresAuth: true,
        }
    },

    {
        name: 'Product Add',
        path: '/add-product',
        component: ProductAdd,
        meta: {
            title: 'Product Add',
            requiresAuth: true,
        }
    },

    {
        name: 'Manage Reviews',
        path: '/manage-reviews',
        component: ManageReviews,
        meta: {
            title: 'Manage Reviews',
            requiresAuth: true,
        }
    },

    {
        name: 'Settings',
        path: '/settings',
        component: Settings,
        meta: {
            title: 'Settings',
            requiresAuth: true,
        }
    },

    {
        name: 'Login',
        path: '/login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;

  if (to.matched.length === 0) {
      next('/product-manager');
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!isLoggedIn) {
          next('/login');
      } else {
          next();
      }
  } else {
      if (isLoggedIn && to.path === '/login') {
          next('/product-manager');
      } else if (!isLoggedIn && to.path !== '/login') {
          next('/login');
      } else {
          next();
      }
  }
});


export default router;