<template>
    <button @click="buttonClick()" class="w-full py-2 px-2 mx-auto rounded-md font-bold text-white bg-primary transition duration-300 ease-in-out hover:bg-secondary">{{ props.buttonText }}</button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    buttonText: {
        type: String,
        default: 'Submit',
    }
});

const emits = defineEmits(['clickEvent']);

const buttonClick = () => {
    emits('clickEvent');
};
</script>