<template>
    <transition name="fade">
        <div v-if="isEditPopupVisible" @click="closePopup()"
            class="w-full h-screen md:p-5 flex justify-start md:justify-center items-center fixed top-0 left-0 right-0 bg-[#00000070] z-10">
            <Loading :loading="isLoading" />
            <div @click.stop
                class="max-w-[667px] w-full max-h-screen sm:max-h-[600px] p-5 md:rounded-md flex flex-col justify-between bg-fg-light dark:bg-fg-dark">
                <div class="h-8 flex justify-end flex-shrink-0 bg-fg-light dark:bg-fg-dark">
                    <button @click="closePopup()">
                        <font-awesome-icon class="w-5 h-5" :icon="['fa', 'close']" />
                    </button>
                </div>
                <div class="flex justify-center overflow-auto">
                    <div class="max-w-[550px] w-full">
                        <p class="mb-5 font-bold">Document Information</p>
                        <InputTextLabel :id="'Title'" :type="'text'" :label="'Title'" :placeholder="'Marketing Plan'"
                            :value="title" :error="errors.title" :isDissabled="false"
                            @inputFieldValueChanged="titleInputFieldValueChange" />
                        <InputSelectLabel :id="'category'" :label="'Category'" :value="selectedCategoryId"
                            :options="props.categories" :error="errors.selectedCategoryId"
                            @inputFieldValueChanged="categoryInputFieldValueChange" />
                        <InputSelectLabel :id="'subCategory'" :label="'Sub Category'" :value="selectedSubCategoryId"
                            :options="props.subCategories" :error="errors.selectedSubCategoryId"
                            @inputFieldValueChanged="subCategoryInputFieldValueChange" />
                        <InputTextLabel :id="'revisionDate'" :type="'date'" :label="'Revision Date'" :placeholder="''"
                            :value="revisionDate" :error="errors.revisionDate" :isDissabled="false"
                            @inputFieldValueChanged="revisionDateInputFieldValueChange" />
                        <div>
                            <div class="w-full h-[1px] my-10 bg-[#86868667]"></div>
                            <p class="mb-5 font-bold">Document Information</p>
                        </div>
                        <div class="max-w-[550px] w-full grid grid-cols-2 gap-4">
                            <InputTextLabel :type="text" :label="'Price (' + CONSTANTS.CURRECNCY_TYPE + ')'"
                                :placeholder="'1.50'" :value="price" :error="errors.price" :isDissabled="false"
                                @inputFieldValueChanged="priceInputFieldChange" />
                            <InputTextLabel :type="text" :label="'Discount (' + CONSTANTS.CURRECNCY_TYPE + ')'"
                                :placeholder="'0.50'" :value="discount" :error="errors.discount" :isDissabled="false"
                                @inputFieldValueChanged="discountInputFieldChange" />
                        </div>
                        <div class="max-w-[550px] w-full mb-10 grid grid-cols-2 gap-4">
                            <div>
                                <p>PAD {{ `(${CONSTANTS.CURRECNCY_TYPE})` }}</p>
                                <div
                                    class="w-full h-10 pl-3 rounded-md flex items-center bg-fg-light dark:bg-fg-dark">
                                    <p>{{ PAD }}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="w-full h-[1px] mb-10 bg-[#86868667]"></div>
                            <p class="mb-5 font-bold">Document Information</p>
                        </div>
                        <div v-if="documentFile === null" class="mb-5">
                            <p>Current Document:</p>
                            <p>{{ props.currentDocument }}</p>
                        </div>
                        <div class="max-w-[550px] mb-10">
                            <p>Change Document</p>
                            <input class="max-w-[550px] w-full border rounded-md" type="file" ref="document"
                                @change="documentUpload()" name="" id="document">
                        </div>
                    </div>
                </div>
                <div class="h-16 flex justify-center items-end flex-shrink-0 bg-fg-light dark:bg-fg-dark">
                    <div class="max-w-[550px] w-full">
                        <BtnText :buttonText="'Update'" @clickEvent="updateItem()" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import InputTextLabel from '@/components/common/label-input-types/label-text.vue';
import InputSelectLabel from '@/components/common/label-input-types/label-select.vue';
import BtnText from '@/components/common/buttons/btn-text.vue';
import { CONSTANTS } from '@/utils/constants';
import { editItem } from '@/services/api';
import Loading from '@/components/common/loading-overlay-com.vue';
import { swal } from '@/utils/helper';

//props
//popup visibility
const props = defineProps({
    isEditPopupVisible: {
        type: Boolean,
        default: false,
    },

    itemId: {
        type: String,
        default: '',
    },

    title: {
        type: String,
        default: '',
    },

    categories: {
        type: Object,
        default: null
    },

    categoryId: {
        type: String,
        default: '',
    },

    subCategories: {
        type: Object,
        default: null,
    },

    subCategoryId: {
        type: String,
        default: '',
    },

    revisionDate: {
        type: String,
        default: '',
    },

    price: {
        type: String,
        default: '',
    },

    discount: {
        type: String,
        default: '',
    },

    PAD: {
        type: String,
        default: '',
    },

    currentDocument: {
        type: String,
        default: '',
    },
});

let isLoading = ref(false);

//update item
let title = ref(props.title);
let revisionDate = ref(props.revisionDate);
const selectedCategoryId = ref(props.categoryId);
const selectedSubCategoryId = ref(props.subCategoryId);
let price = ref(props.price);
let discount = ref(props.discount);
let PAD = ref(props.PAD);
const document = ref(null);
let documentFile = ref(null);

//inputs errors
let errors = ref({
    title: '',
    revisionDate: '',
    selectedCategoryId: '',
    selectedSubCategoryId: '',
    price: '',
    discount: '',
});

//emits
const emits = defineEmits(['popupClose', 'changeSubCategories', 'changePAD']);

//upload a new document
const documentUpload = () => {
    documentFile.value = document.value.files[0];
}

//get new title from input from emit
const titleInputFieldValueChange = (val) => {
    title.value = val;

    //make error null in input value changed
    errors.value.title = '';
};

const categoryInputFieldValueChange = (val) => {
    selectedCategoryId.value = val;
    changeSubCategories();
};

const subCategoryInputFieldValueChange = (val) => {
    selectedSubCategoryId.value = val;
};

const revisionDateInputFieldValueChange = (val) => {
    revisionDate.value = val;
};

const priceInputFieldChange = (val) => {
    price.value = val;
    calculatePAD();
};

const discountInputFieldChange = (val) => {
    discount.value = val;
    calculatePAD();
};

const calculatePAD = () => {
    PAD.value = (parseFloat(price.value) - parseFloat(discount.value)).toFixed(2);
    console.log(PAD.value)
};

//popup close emit
const closePopup = () => {
    emits('popupClose', false);
};

//sub categories change emit
const changeSubCategories = () => {
    emits('changeSubCategories', selectedCategoryId.value);
};

const validation = () => {
    if (title.value === '') {
        errors.value.title = 'Title is required!';
    } else {
        errors.value.title = '';
    }

    if (selectedCategoryId.value === '') {
        errors.value.selectedCategoryId = 'Category is required!';
    } else {
        errors.value.selectedCategoryId = '';
    }

    if (selectedSubCategoryId.value === '') {
        errors.value.selectedSubCategoryId = 'Sub category is required!';
    } else {
        errors.value.selectedSubCategoryId = '';
    }

    if (revisionDate.value === '') {
        errors.value.revisionDate = 'Revision date is required!';
    } else {
        errors.value.revisionDate = '';
    }

    if (price.value === '') {
        errors.value.price = 'Price is required!';
    } else if (isNaN(price.value)) {
        errors.value.price = 'Invalid input!';
    } else {
        errors.value.price = '';
    }

    if (discount.value > price.value) {
        errors.value.discount = 'Discount must be less than the price!'
    } else if (isNaN(discount.value)) {
        errors.value.discount = 'Invalid input!';
    } else {
        errors.value.discount = '';
    }
};

const updateItem = async () => {

    validation();

    if (errors.value.title === '' && errors.value.selectedCategoryId === '' && errors.value.selectedSubCategoryId === '' && errors.value.revisionDate === '' && errors.value.price === '') {

        let postData = new FormData();

        postData.append('id', props.itemId);
        postData.append('title', title.value);
        postData.append('revisionDate', revisionDate.value);
        postData.append('categoryId', selectedCategoryId.value);
        postData.append('subCategoryId', selectedSubCategoryId.value);
        postData.append('price', price.value);
        postData.append('discount', discount.value);
        postData.append('file', documentFile.value);

        isLoading.value = true;
        try {
            const response = await editItem(postData);
            swal(
                    response.data.title, 
                    response.data.message, 
                    'success', 
                    false, () => { 
                        closePopup();
                        window.location.reload();
                    }, 
                    () => { }, 
                    () => { }
                );
        } catch (error) {
            swal(error.response.data.title, error.response.data.message, 'error', false, () => { }, () => { }, () => { });
        }
        isLoading.value = false;
    }
};

</script>