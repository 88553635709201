<template>
    <div class="max-w-[270px]">
        <router-link to="/">
            <img src="../../../assets/logo/logo.png" alt="">
        </router-link>
        <div class="mt-10">
            <router-link to="/product-manager">
                <div class="link-box p-3 flex items-center rounded-md">
                    <font-awesome-icon class="link-icon w-4 h-4 mr-5" :icon="['fa', 'bars-progress']" />
                    <p class="text-sm">Product Manager</p>
                </div>
            </router-link>
            <router-link to="/add-product">
                <div class="link-box p-3 flex items-center rounded-md">
                    <font-awesome-icon class="link-icon w-4 h-4 mr-5" :icon="['fa', 'circle-plus']" />
                    <p class="text-sm">Add Product</p>
                </div>
            </router-link>
            <router-link to="/manage-reviews">
                <div class="link-box p-3 flex items-center rounded-md">
                    <font-awesome-icon class="link-icon w-4 h-4 mr-5" :icon="['fa', 'star']" />
                    <p class="text-sm">Manage Reviews</p>
                </div>
            </router-link>
            <router-link to="/settings">
                <div class="link-box p-3 flex items-center rounded-md">
                    <font-awesome-icon class="link-icon w-4 h-4 mr-5" :icon="['fa', 'gear']" />
                    <p class="text-sm">Settings</p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<style scoped>
    a.router-link-exact-active .link-box {
        background: linear-gradient(to right, #7284FE, #070031);
        color: white;
    }

    a.router-link-exact-active .link-icon {
        color: white;
    }
</style>